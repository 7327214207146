import { css } from '@emotion/core'
import { dimensions } from './variables'

import { Props as SelectProps } from 'react-select'

export default css`
	/* Form validation messages */
	.is-invalid .form-control,
	form.was-validated .form-control:invalid {
		border-color: rgb(var(--clr-warning));

		&:focus {
			border-color: rgb(var(--clr-warning));
			box-shadow: 0 0 ${dimensions.focusBlur}px ${dimensions.focusWidth}px rgba(var(--clr-warning), 0.8);
		}

		~ .invalid-feedback {
			display: block;
		}
	}

	form label {
		display: block;
		margin-bottom: 0.25rem;
	}

	.invalid-feedback,
	.form-text {
		margin-top: 0.25rem;
	}

	.form-select,
	.form-control {
		color: #3c3c3c;
		background-color: white;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}

	/* Form input */
	/* Select elements are styled in SelectStyle object below. */
	.form-control {
		display: block;
		width: 100%;
		border: ${dimensions.borderWidth}px solid WhiteSmoke;

		font-size: ${dimensions.fontSize.regular}rem;
		line-height: ${dimensions.lineHeight.regular};
		padding: 0.375rem 0.75rem;

		/* Undo the iOS default */
		border-radius: 0;

		&:focus {
			border-color: rgb(var(--clr-accent));
			box-shadow: 0 0 ${dimensions.focusBlur}px ${dimensions.focusWidth}px rgba(var(--clr-accent), 0.9);
			outline: 0;
		}

		&::placeholder {
			color: DarkGrey;
			opacity: 1;
		}

		&:disabled,
		&[readonly] {
			background-color: WhiteSmoke;
			opacity: 1;
		}

		&[type='number'] {
			-moz-appearance: textfield;
		}
	}

	.form-check-label {
		padding-left: 2rem;
		margin-right: ${dimensions.containerPadding}rem;
		position: relative;

		/* Foreground (icon) */
		&:after {
			content: '';
			position: absolute;
			display: block;
			top: 0.125rem;
			left: 0.5rem;
			width: 0.5rem;
			height: 1rem;
			pointer-events: none;
			user-select: none;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 50% 50%;
		}

		/* Background-color */
		&:before {
			content: '';
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			width: 1.5rem;
			height: 1.5rem;
			pointer-events: none;
			user-select: none;
			background-color: white;
			transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
			border: ${dimensions.borderWidth}px solid WhiteSmoke;
		}
	}

	.form-check-input {
		position: absolute;
		z-index: -1;
		opacity: 0;

		/* Make radio buttons round */
		&[type='radio'] ~ .form-check-label:after,
		&[type='radio'] ~ .form-check-label:before {
			border-radius: 50%;
		}

		/* checked icon */
		&:checked ~ .form-check-label:after {
			border: solid white;
			transform: rotate(35deg);
			border-width: 0 3px 3px 0;
		}

		/* checked background */
		&:checked ~ .form-check-label:before {
			background-color: rgb(var(--clr-accent));
			border-color: rgb(var(--clr-accent));
		}

		/* focus background */
		&:focus ~ .form-check-label:before {
			box-shadow: 0 0 ${dimensions.focusBlur}px ${dimensions.focusWidth}px rgba(var(--clr-accent), 0.9);
			border-color: rgb(var(--clr-accent));
		}
	}

	/* sk-toggle-option added for search kit buttons in Finnish recipe bank */
	.sk-toggle-option,
	.btn,
	button {
		color: inherit;
		display: inline-block;
		text-align: center;
		vertical-align: middle;
		user-select: none;
		outline: 0;
		border: ${dimensions.borderWidth}px solid;
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15ms ease-in-out,
			box-shadow 0.15s ease-in-out;

		font-weight: bold;
		font-size: ${dimensions.fontSize.regular}rem;
		line-height: ${dimensions.lineHeight.regular};
		padding: 0.375rem 0.75rem;
		border-radius: 4px;
		opacity: 1;

		background-color: rgb(var(--clr-primary));
		border-color: rgb(var(--clr-primary));

		&:hover {
			text-decoration: none;
			background-color: rgba(var(--clr-primary), 0.85);
			border-color: rgba(var(--clr-primary), 0.85);
		}

		&:focus {
			text-decoration: none;
			border-color: rgb(var(--clr-accent));
			box-shadow: 0 0 ${dimensions.focusBlur}px ${dimensions.focusWidth}px rgba(var(--clr-accent), 0.9);
			outline: 0;
		}

		&:disabled {
			opacity: 0.65;
			box-shadow: none;
		}

		/* Opinionated: add "hand" cursor to non-disabled buttons */
		&:not(:disabled) {
			cursor: pointer;
		}
	}

	button::-moz-focus-inner {
		border: 0;
	}

	.custom-submit-button {
		span {
			transition: all 1s;
			opacity: 1;
		}
		svg {
			display: none;
			margin: 0 auto;
			transition: all 1s;
			path,
			rect {
				fill: #fff;
			}
		}
	}

	.form-submitting .custom-submit-button {
		span {
			display: none;
			opacity: 0;
		}
		svg {
			display: block;
			opacity: 1;
		}
	}
`

export const SelectStyle: SelectProps['styles'] = {
	control: (base, state) => ({
		...base,

		// see .form-control
		border: `${dimensions.borderWidth}px solid WhiteSmoke`,
		fontSize: `${dimensions.fontSize.regular}rem`,
		lineHeight: `${dimensions.lineHeight.regular}`,
		borderRadius: 0,
		color: 'black',

		// Focus style.
		...(state.isFocused && {
			boxShadow: `0 0 ${dimensions.focusBlur}px ${dimensions.focusWidth}px rgba(var(--clr-accent), 0.9)`,
			borderColor: 'rgb(var(--clr-accent)) !important'
		}),

		// Disable hover style.
		':hover': {
			borderColor: 'WhiteSmoke'
		}
	})
}
