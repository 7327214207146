import React from 'react'
import styled from '@emotion/styled'

import { dimensions } from '../../style/variables'

const StyledHamburger = styled.label`
	cursor: pointer;
	transition: opacity 0.15s linear;
	color: inherit;
	border: 0;
	margin: 0;

	&:hover {
		opacity: 0.7;
	}

	.hamburger-box {
		width: 40px;
		height: 24px;
		display: inline-block;
		position: relative;
	}

	.hamburger-inner,
	.hamburger-inner::before,
	.hamburger-inner::after {
		display: block;
		width: 40px;
		height: 4px;
		background-color: white;
		border-radius: 4px;
		position: absolute;
		transition: color 0.15s ease, transform 0.15s ease;
	}

	.hamburger-inner {
		top: 2px;
		margin-top: -2px;

		&::before,
		&::after {
			content: '';
		}

		&::before {
			top: 10px;
		}

		&::after {
			bottom: -10px;
			top: 20px;
		}
	}
`

const StyledHamburgerControl = styled.button`
	/** Visually hidden */
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	white-space: nowrap;

	&:focus ~ ${StyledHamburger} .hamburger-box {
		box-shadow: 0 0 ${dimensions.focusBlur}px ${dimensions.focusWidth * 2}px rgba(var(--clr-accent), 0.9),
			0 0 ${dimensions.focusBlur}px ${dimensions.focusWidth}px rgb(var(--clr-background), 0.9);
	}

	&[aria-expanded='true'] ~ ${StyledHamburger} .hamburger-inner::before,
	&[aria-expanded='true'] ~ ${StyledHamburger} .hamburger-inner::after {
	}

	&[aria-expanded='true'] ~ ${StyledHamburger} .hamburger-inner {
		transform: translate3d(0, 10px, 0) rotate(45deg);
	}

	&[aria-expanded='true'] ~ ${StyledHamburger} .hamburger-inner::before {
		transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
		opacity: 0;
	}

	&[aria-expanded='true'] ~ ${StyledHamburger} .hamburger-inner::after {
		transform: translate3d(0, -20px, 0) rotate(-90deg);
	}
`

interface Props {
	className?: string
	label?: string
	controls?: string
	onClick: React.MouseEventHandler<HTMLElement>
	expanded: boolean
}

const Hamburger: React.FC<Props> = ({ className, onClick, label, controls, expanded }) => (
	<>
		<StyledHamburgerControl
			id="hamburger"
			aria-label={label}
			aria-controls={controls}
			aria-expanded={expanded}
			className={className}
			onClick={onClick}
		/>
		<StyledHamburger htmlFor="hamburger" className={className}>
			<span className="hamburger-box">
				<span className="hamburger-inner" />
			</span>
		</StyledHamburger>
	</>
)

export default Hamburger
