interface Translation {
	[langcode: string]: {
		[key: string]: string
	}
}

/**
 * Source of translated strings for t() function.
 *
 * English strings don't need to be defined here as t() will fall back to
 * the argument string if the translation is missing from this object.
 *
 * This should throw away unused languages when compiled with webpack.
 */
const translations = ({
	fi: {
		'Read more': 'Lue lisää',
		'Open menu': 'Avaa valikko',
		Recipe: 'Resepti',
		Portions: 'Annoksia',
		'Follow us on social media': 'Seuraa meitä sosiaalisessa mediassa',
		'The requested URL was not found': 'Voi ei! Sivua ei löytynyt!'
	},
	lt: {
		'Read more': 'Daugiau',
		'Open menu': 'Meniu',
		Recipe: 'Receptas',
		Portions: 'Porcijų kiekis',
		'Follow us on social media': 'Sekite mus socialiniuose tinkluose',
		'The requested URL was not found': 'Puslapis nerastas'
	},
	nb: {
		'Read more': 'Les mer',
		'Open menu': 'Åpne meny',
		Recipe: 'Oppskrift',
		Portions: 'Porsjoner',
		'Follow us on social media': 'Følg oss på sosiale medier',
		'The requested URL was not found': 'Den ønskede URL ble ikke funnet'
	},
	'nb-cc': {
		'Read more': 'Les mer',
		'Open menu': 'Åpne meny',
		Recipe: 'Oppskrift',
		Portions: 'Porsjoner',
		'Follow us on social media': 'Følg oss på sosiale medier',
		'The requested URL was not found': 'Den ønskede URL ble ikke funnet'
	},
	da: {
		'Read more': 'Læs mere',
		'Open menu': 'Åbn menu',
		Recipe: 'Opskrift',
		Portions: 'Portioner',
		'Follow us on social media': 'Følg os på sociale medier',
		'The requested URL was not found': 'Den ønskede URL blev ikke fundet'
	},
	'da-cc': {
		'Read more': 'Læs mere',
		'Open menu': 'Åbn menu',
		Recipe: 'Opskrift',
		Portions: 'Portioner',
		'Follow us on social media': 'Følg os på sociale medier',
		'The requested URL was not found': 'Den ønskede URL blev ikke fundet'
	},
	uk: {
		'Read more': 'Дізнатися більше',
		'Open menu': 'Відкрити меню',
		Recipe: 'Рецепт',
		Portions: 'Порції',
		'Follow us on social media': 'Підписуйтеся на наші соцмережі',
		'The requested URL was not found': 'URL не знайдено'
	},
	ru: {
		'Read more': 'Прочитать больше',
		'Open menu': 'Открыть меню',
		Recipe: 'Рецепт',
		Portions: 'Порции',
		'Follow us on social media': 'Подписывайтесь на наши социальные сети',
		'The requested URL was not found': 'Запрашиваемый URL не был найден'
	},
	pl: {
		'Read more': 'Czytaj więcej',
		'Open menu': 'Otwórz menu',
		Recipe: 'Przepisy',
		Portions: 'Porcje',
		'Follow us on social media': 'Obserwuj nas w social mediach',
		'The requested URL was not found': 'Nie znaleziono żądanego adresu URL'
	},
	et: {
		'Read more': 'Loe lisaks',
		'Open menu': 'Ava menüü',
		Recipe: 'Retsept',
		Portions: 'Portsjonid',
		'Follow us on social media': 'Jälgi meid sotsiaalmeedias',
		'The requested URL was not found': 'URLi ei leitud'
	}
} as Translation)[process.env.GATSBY_LANGUAGE as string]

/**
 * Translates a string to the current language
 *
 * @example
 * t('Read more'); // Return 'Lue lisää' for Finnish site.
 *
 * @param str A string containing the English text to translate.
 * @returns translated string or the english string as a fallback.
 */
export const t = (str: string) => {
	return (translations && translations[str]) || str
}
