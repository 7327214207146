/**
 * Defined breakpoint names in ascending order.
 *
 * This is to make sure we don't have to rely on object key order. Key order
 * is not guaranteed by ECMAScript's spec even though most browsers will
 * respect it.
 */
export const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'] as const

export type Breakpoint = typeof breakpoints[number]

/**
 * Define the minimum dimensions at which your layout will change,
 */
export const breakpointValues: { [breakpoint in Breakpoint]: number } = {
	xs: 0,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200
}

function nextBreakpoint(breakpoint: Breakpoint) {
	return breakpointValues[breakpoints[breakpoints.indexOf(breakpoint) + 1]]
}

/**
 * Define maximum container width for given breakpoint.
 */
export const widths: Partial<{ [width in Breakpoint]: number }> = {
	sm: 540,
	md: 720,
	lg: 960,
	xl: 1140
}

/**
 * Define media queries.
 */
export const media = {
	/** Style from breakpoint and up. Not defined for smallest breakpoint. */
	up: (breakpoint: Breakpoint) => `@media (min-width: ${breakpointValues[breakpoint]}px)`,

	/** Style from breakpoint and down. Not defined for largest breakpoint. */
	down: (breakpoint: Breakpoint) => {
		if (breakpoint === breakpoints[breakpoints.length - 1]) {
			return `@media all`
		}

		return `@media (max-width: ${nextBreakpoint(breakpoint) - 1}px)`
	}
}

const fontFormat = (font?: string) => {
	if (font) {
		return font.split(':')[0].replace('+', ' ')
	}
}
const primaryFont = fontFormat(process.env.GATSBY_PRIMARY_FONT)

// Secondary font is not defined here because "system" fonts give better performance.
// Discussion: https://github.com/necolas/normalize.css/issues/665
export const fonts = {
	primary: `${primaryFont}, Georgia, "Times New Roman", Times, serif`,
	secondary:
		'-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif',
	monospace: 'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace, monospace'
}

export const dimensions = {
	fontSize: {
		regular: 1,
		large: 1.2
	},
	headingSizes: {
		h1: 2.5,
		h2: 2.25,
		h3: 1.75,
		h4: 1.25
	},
	lineHeight: {
		regular: 1.45,
		heading: 0.9
	},
	containerPadding: 1.5,
	borderWidth: 3,
	focusWidth: 2,
	focusBlur: 3
} as const

export const heights = {
	header: dimensions.containerPadding * 2 + dimensions.fontSize.regular * dimensions.lineHeight.regular
}
