import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'

import { dimensions, media } from '../style/variables'
import { DrupalProcessedText, DrupalMedia, DrupalColor } from '../drupal'
import Container from './Container'
import SocialMedia from './blocks/SocialMedia'
import Media from './fields/Media'
import { backgroundColorStyle } from '../utils'

interface Props extends React.HTMLProps<HTMLElement> {}

interface StaticQueryProps {
	blockContentFooter: {
		body: DrupalProcessedText
		field_background_color: DrupalColor
		relationships: {
			field_footer_logo: DrupalMedia[]
		}
	}
}

const StyledFooter = styled.footer`
	background-color: rgb(var(--clr-background));
	margin-top: auto;
	padding-top: ${dimensions.containerPadding}rem;
	padding-bottom: ${dimensions.containerPadding}rem;
`

const StyledContainer = styled(Container)`
	display: flex;
	justify-content: space-between;
	color: white;

	.text {
		max-width: 300px;
	}

	.social-media {
		justify-content: flex-end;
		font-size: 1.125rem;
		flex-direction: row;
		margin-left: auto;
		display: flex;
		grid-area: social;

		li {
			padding: 0 0.75rem;
		}
	}

	${media.up('lg')} {
		.logos {
			display: flex;
			align-items: center;
			justify-content: space-around;
			width: 100%;
		}
	}
	${media.down('sm')} {
		flex-direction: column;
		text-align: center;

		.text {
			max-width: unset;
		}

		.logos {
			padding-bottom: ${dimensions.containerPadding}rem;
		}
		.logos .gatsby-image-wrapper {
			display: block !important;
			margin: auto;
		}

		.social-media {
			justify-content: center;
		}
	}
`

const Footer: React.FC<Props> = props => {
	const { blockContentFooter }: StaticQueryProps = useStaticQuery(graphql`
		{
			blockContentFooter {
				body {
					processed
				}
				field_background_color {
					color
				}
				relationships {
					field_footer_logo {
						__typename
						... on media__logo {
							...MediaLogo
						}
					}
				}
			}
		}
	`)
	return (
		<StyledFooter {...props} style={backgroundColorStyle(blockContentFooter.field_background_color)}>
			<StyledContainer>
				<div className="logos">
					{blockContentFooter.relationships.field_footer_logo.map(logo => (
						<Media source={logo} key={logo.drupal_internal__mid} />
					))}
				</div>
				<div className="text">
					<div
						dangerouslySetInnerHTML={{
							__html: blockContentFooter.body.processed
						}}
					/>
					<SocialMedia />
				</div>
			</StyledContainer>
		</StyledFooter>
	)
}

export default Footer
